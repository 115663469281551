import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../constants";

const Tab = styled.div`
  color: #000000;
  z-index: -1;
  position: absolute;
  top: 56px;
  width: 55px;
  border: 1px solid ${theme.colors.header.tabBorder};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 8px 15px 8px 15px;
  text-align: center;
  z-index: 90;
  background-image: linear-gradient(
    ${theme.colors.header.tabBackgroundLight},
    ${theme.colors.header.tabBackground}
  );
`;

const ActiveTab = styled(Tab)`
  background-image: linear-gradient(
    ${theme.colors.header.tabBackgroundLight},
    white
  );
  font-weight: bold;
  border-bottom: 1px solid #ffffff;
`;

const Link = styled.a`
  text-decoration: none;
  color: #000000;
  padding: 8px 15px 8px 15px;

  &:visited {
    color: #000000;
  }
  &:hover {
    color: ${theme.colors.pageHeading};
  }
`;

class HeaderTab extends Component {
  render() {
    return (
      <div>
        {this.props.isActive ? (
          <ActiveTab style={{ right: this.props.rightOffset }}>
            {this.props.label}
          </ActiveTab>
        ) : (
          <Tab style={{ right: this.props.rightOffset }}>
            <Link href={this.props.link}>{this.props.label}</Link>
          </Tab>
        )}
      </div>
    );
  }
}

export default HeaderTab;
